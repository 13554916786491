<template>
  <div id="containernew">
    <!--div id="container" class="svg-container"-->
    <svg :id="'verticalrankskill' + id"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    id: {
      type: String,
      required: true,
      default: null,
    },
    width: {
      type: Number,
      default: 500,
    },
    height: {
      type: Number,
      default: 120,
    },
    data: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      const margin = { top: 10, right: 10, bottom: 50, left: 30 };
      const width = this.width - margin.left - margin.right;
      const height = this.height - margin.top - margin.bottom;

      const y = d3
        .scaleLinear()
        .domain([0, this.max ? this.max : 10])
        .range([height, 0]);

      const x = d3
        .scaleBand()
        .range([0, width])
        .domain(
          this.data.map(function (d) {
            return d.player;
          })
        )
        .padding(0.2);

      const svg = d3
        .select("#verticalrankskill" + this.id)
        //  .attr("preserveAspectRatio", "xMinYMin meet")
        .attr(
          "viewBox",
          `0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom
          }`
        );
      //  .classed("svg-content", true);

      this.chart = svg
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      this.chart
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .attr("class", "grid")
        .call(d3.axisBottom(x).tickSize(0))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("font-size", "0.25rem")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)");

      this.chart
        .append("g")
        .attr("class", "grid")
        .call(d3.axisLeft(y).tickSize(0))
        .selectAll("text")
        .attr("font-size", "0.32rem");

      this.chart
        .selectAll(".bar")
        .data(this.data)
        .enter()
        .append("rect")
        .attr("fill", function (d) {
          return d.color;
        })
        .attr("y", y(0))
        .attr("height", height - y(0))
        .attr("x", function (d) {
          return x(d.player);
        })
        .attr("width", x.bandwidth())
        .transition()
        .duration(750)
        .delay(function (d, i) {
          return i * 150;
        })
        .attr("y", function (d) {
          return y(d.score > 0 ? d.score : 0);
        })
        .attr("height", function (d) {
          return height - y(d.score > 0 ? d.score : 0);
        });

      /*   this.chart
        .selectAll(".label")
        .data(this.data)
        .enter()
        .append("text")
        .attr("text-anchor", "middle")
        .attr("fill", "#FFFFFF")
        .attr("fill", function (d) {
          return d.colorLabel ? d.colorLabel : "#FFFFFF";
        })
        .attr("font-size", "0.5rem")
        .attr("cursor", "pointer")
        .attr("class", "label")
        .attr("y", y(0))
        .attr("x", function (d) {
          return x(d.player ? d.player.id : "");
        })
        .text(function (d, i) {
          return (
              d.player ?
              d.player.name : ""
          );
        })
        .on("click", function (d, i) {
          var link = i.player
            ? "/scouting/view/player/" + i.player.id
            : "";
          window.open(link);
        });  */

      this.chart
        .selectAll(".value")
        .data(this.data)
        .enter()
        .append("text")
        .attr("fill", "#FFFFFF")
        .attr("font-weight", "bold")
        .attr("font-size", "0.3rem")
        .attr("class", "value")
        .attr("x", function (d) {
          return x(d.player) + x.bandwidth() / 2 - 2;
        })
        .attr("y", function (d) {
          return y(d.score) - 3;
        })
        .text(function (d) {
          return d.score != "" &&
            !isNaN(d.score) &&
            Math.round(d.score) != d.score
            ? Math.round(d.score * 10) / 10
            : d.score;
        });

      this.chart
        .selectAll(".value2")
        .data(this.data)
        .enter()
        .append("text")
        .attr("fill", "#000000")
        .attr("font-weight", "bold")
        .attr("font-size", "0.3rem")
        .attr("class", "value2")
        .attr("x", function (d) {
          return x(d.player) + 1;
        })
        .attr("y", function (d) {
          return y(0) - 1;
        })
        .text(function (d) {
          return d.value != "" &&
            !isNaN(d.value) &&
            Math.round(d.value) != d.value
            ? Math.round(d.value * 10) / 10
            : d.value;
        });

      /*

      this.chart
        .selectAll(".label")
        .data(this.data)
        .enter()
        .append("text")
        //  .attr("fill", "#FFFFFF")
        .attr("fill", function (d) {
          return d.colorLabel ? d.colorLabel : "#FFFFFF";
        })
        .attr("font-size", "0.5rem")
        .attr("cursor", "pointer")
        .attr("class", "label")
        .attr("y", function (d) {
          return (
            y(d.coach ? d.coach.id : d.player ? d.player.id : "") +
            y.bandwidth() / 2 +
            3
          );
        })
        .attr("x", function (d) {
          return x(0) - 55;
        })
        .text(function (d, i) {
          return (
            (d.rank ? d.rank : i) +
            1 +
            " - " +
            (d.coach
              ? d.coach.allenatore
                ? d.coach.allenatore.cognome
                : d.coach.name
              : d.player
              ? d.player.name
              : "")
          );
        })
        .on("click", function (d, i) {
          var link = i.coach
            ? "/scouting/view/coach/" + i.coach.id
            : i.player
            ? "/scouting/view/player/" + i.player.id
            : "";
          window.open(link);
        });

      this.chart
        .selectAll(".image")
        .data(this.data)
        .enter()
        .append("image")
        .attr("xlink:href", (d) =>
          d.coach
            ? d.coach.allenatore
              ? d.coach.allenatore.avatar_url
              : "/assets/images/agente.png"
            : d.player
            ? d.player.avatar
              ? d.player.avatar
              : "/assets/images/profile.png"
            : "/assets/images/profile.png"
        )
        .attr("width", "10")
        .attr("height", "10")
        .attr("class", "image")
        .attr("y", function (d) {
          return (
            y(d.coach ? d.coach.id : d.player ? d.player.id : "") +
            y.bandwidth() / 2 +
            3 -
            10
          );
        })
        .attr("x", function (d) {
          return x(0) - 70;
        });  */
    },
  },
};
</script>
<style>
.tooltip {
  position: absolute;
  z-index: 999;
  width: 150px;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -mox-box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rbga(0, 0, 0, 0.4);
  pointer-events: none;
}
.tooltip.hidden {
  opacity: 0;
}
.tooltip p {
  margin: 0;
  font-size: 10px;
  line-height: 20px;
}

.grid {
  font: 10px sans-serif;
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}
</style>
